<template>
  <b-card title="Editar Jornada">
    <b-row>
      <b-col md="3">
        <div class="form-group">
          <label>Jornada</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="form.nombre"
          />
        </div>
      </b-col>
      <b-col md="3">
        <div class="form-group">
          <label>Observación</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="form.observacion"
          />
        </div>
      </b-col>
      <b-col md="3">
        <div class="form-group">
          <label>Color</label>
          <input
            type="color"
            class="form-control form-control-sm"
            v-model="form.color"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-end">
          <router-link
            :to="{ name: 'days' }"
            class="btn btn-sm btn-circle btn-link mr-3"
          >
            <i class="la la-reply"></i> Regresar
          </router-link>
          <button
            class="btn btn-sm btn-circle btn-success"
            @click="updateDay(id)"
            v-if="can('edit-day')"
          >
            <i class="flaticon-edit-1"></i>
            {{ loading ? "Cargando..." : "Actualizar" }}
          </button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import can from "@/permission";
import toastr from "toastr";
export default {
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      form: {
        codigo: null,
        nombre: null,
        observacion: null,
        color: null,
      },
    };
  },
  mounted() {
    this.loadDay();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    can: can,
    loadDay() {
      axios
        .get(`/tipo-jornadas/${this.id}`)
        .then((res) => {
          const data = res.data;
          this.form = data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateDay(id) {
      if (this.form.nombre && this.form.observacion && this.form.color) {
        axios
          .put(`/tipo-jornadas/${id}`, this.form)
          .then((res) => {
            if (res.data.message == "success") {
              toastr.success(res.data.information);
              this.loadDay();
            } else {
              toastr.info(res.data.information);
            }
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        const keys = ["codigo", "deleted_at"];
        for (const key in this.form) {
          if (!keys.includes(key)) {
            if (!this.form[key]) {
              toastr.info(`El campo ${key} es obligatorio`);
            }
          }
        }
      }
    },
  },
};
</script>
